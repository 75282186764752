<template>
  <div class="app-indexpage traceinfopage growpage u-p-30 u-rela">
    <img
      class="imgbg imgbg5 u-abso"
      src="../../assets/images/applet/index/bg5.png"
      alt=""
    />
    <div class="bggray radius-60">
      <!-- 为爱溯源 -->
      <div class="u-p-22 u-p-t-40 u-rela">
        <div class="title u-flex">
          <h2 class="u-flex-1 u-font-38 u-main-color u-m-b-0">为爱溯源</h2>
          <i class="iconfont iconcheck_fill green2 u-font-30 u-m-r-10"></i>
          <span class="green2 u-font-30">可追溯</span>
        </div>
        <p class="u-tips-color u-font-32 u-m-t-20">
          通过官方旗舰店购买，可对心爱的人留言，回忆过去。
        </p>
        <div class="panel shadow2 u-p-b-100 u-rela index-1">
          <!-- 格式 -->
          <div>
            <div class="u-text-center">
              <div
                class="dotline u-flex u-flex-col u-row-center u-font-36 green4 auto"
              >
                <span>致亲爱的消费者</span>
              </div>
            </div>
            <p class="u-font-28 u-content-color u-m-t-30">
              你的心思我每时每刻都在意，在那几天多带给你一点甜蜜。skr～<br/>
              它不只是一杯红糖，而是我被你融化的爱。<br/>
              酒我来挡，糖你来喝，金屋不就是用来藏娇的嘛！<br/>
              它很甜，就像我们刚认识时你的样子。
            </p>
            <div class="u-flex u-row-between u-font-28">

              <span class="u-content-color"></span>
              <span class="u-p-6 u-p-l-20 u-p-r-20 radius-5"
              >留言人：晓花匠</span
              >
            </div>
            <!-- 操作人 -->
<!--            <div class="panel caozuoren u-m-t-40">-->
<!--              <div class="u-flex u-row-between u-m-t-30">-->
<!--                <div class="u-flex u-flex-col">-->
<!--                </div>-->
<!--                <div class="u-flex u-flex-col">-->
<!--                </div>-->
<!--                <div class="u-flex u-flex-col">-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
@import "../../assets/css/applet.scss";
</style>
